<template>
  <div>
    <el-form ref="form" status-icon :model="form" :rules="rules">
      <el-row :gutter="20">
        <el-col :span="20">
          <el-form-item prop="model" size="medium">
            <span class="input--label d-block" :class="mode ? 'text__day2' : 'text__night2'">
              {{ $t("message.car_model") }}
            </span>
            <crm-input
              :inputValue="form.model"
              v-model="form.model"
              :placeholder="$t('message.car_model')"
              :class="mode ? 'input__day' : 'input__night'"
            ></crm-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitModel()"> Сохранить </el-button>
      <el-button type="warning" @click="close()"> Закрыть </el-button>
    </span>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import dialog from "@/utils/mixins/dialog";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [dialog],
  data() {
    return {
      form: {},
      rules: {},
      loadingButton: false,
    };
  },
  props: {
    guest_car_type_id: {
      required: true,
    },
  },
  methods: {
    ...mapActions({
      save: "guestCarModel/store",
      updateInventory: "guestCarModel/inventory",
    }),
    submitModel() {
      console.log('salom');
      this.$refs["form"].validate((valid) => {
        if (valid && !this.loadingButton && this.guest_car_type_id) {
          this.loadingButton = true;
          this.form.guest_car_type_id = this.guest_car_type_id;
          this.save(this.form)
            .then((res) => {
              this.$alert(res);
              if (res.status == 201) {
                this.updateInventory();
                this.close();
                this.$parent.$parent.form.guest_car_model_id =res.data.result.data.guest_car_model_id;
                  
              }
              this.loadingButton = false;
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>
